<template>
  <div class="home">
    <main>
      <section class="description">
        <h1 class="uppercase">Hi, I'm Bernardo Soriano</h1>
        <p></p>
        <p>
          I’m a software engineer and I've been designing and developing web and
          mobile applications since 2013. I’ve worked in multiple design and
          development studios like IBMiX, BCG Platinion, Accenture and KTC
          Agency.
        </p>
        <div alt="bernardo soriano profile picture" class="ber-meet"></div>
        <h2>Career FAQs and short stories to inspire.</h2>
        <h3>
          Was working in web development something you’ve always wanted to do,
          or was it something you pivoted towards later on?
        </h3>
        <p>
          Everything started with Powerpoint in junior high school (2004?), I
          enjoyed creating interactive presentations and programming animations
          and transitions during the computer class. Around 2008 I created my
          first websites using MS Front-Page and Macromedia Dreamweaver at
          school, I enjoyed it at that time and started creating web sites as a
          hobby between 2008-2012; I didn’t imagine at that time it would be my
          full time job 10 years later.
        </p>
        <p>
          In 2012 I participated in a hackathon by mistake and had lots of fun
          developing a mobile prototype that a client loved, that was the moment
          when I decided to pursue a career in software development.
        </p>
        <p>
          In 2013 I got my first job as a Front-end developer and I've been
          having fun since then. I always wanted to be an engineer but I wasn’t
          sure about what type of engineer as of today I’m still discovering
          what type of engineer I am.
        </p>
        <h3>What’s been the coolest thing you’ve worked on professionally?</h3>
        <p>
          I’ve worked on so many cool projects, one of my latest projects was an
          interactive data grid with lots of validations and business rules;
          imagine a matrix of cells and some of them had interactive components
          like dropdowns and inputs that changed the rows behavior so that was a
          great project because of its complexity.
        </p>
        <p>
          Design systems; I’ve led the development of design systems in the past
          that allow teams of developers to reuse components, these projects are
          pretty cool too it's like creating the pieces of a massive puzzle.
        </p>
        <p>
          Online banking applications; I’ve worked on applications that help
          people doing banking operations in Mexico and the United States, I
          feel happy to say I coded some pieces of that application that is used
          by millions of people.
        </p>
        <p>
          Design and development of interactive products. I’ve been lucky to
          design features for healthcare platforms, Banking ATMs and Trading
          applications. I like to imagine how users will interact with the
          features I’m building and pull feedback to deliver enhanced
          experiences.
        </p>
        <h3>
          What’s the best part about helping bring web applications to life?
        </h3>
        <p>
          The best part is looking at users who are happy with getting their
          problems solved; helping people by giving them software that will help
          them to complete an online transaction, to connect people with their
          doctors or to do online shopping. Solving people’s problems, making
          their lives easier and fun, that’s one of the fundamentals of the
          things I do.
        </p>
        <p>
          I’m on a mission with delivering experiences to improve people's lives
          and advance human capabilities.
        </p>

        <h3>
          What is the newest tech you are using, learning, excited about,
          curious about, and why?
        </h3>
        <p>
          I’m very excited about cloud technologies recently, most of my
          experience goes around front end and web technologies so cloud
          technologies help me with shipping complete apps quickly. I’m learning
          these days AWS Amplify.
        </p>
        <p>
          There are lots of conversations on Twitter about moving back the
          front-end to the server like in the old days so I’m very curious about
          it.
        </p>
        <p>
          Recently I’m using Vue.js and I’m finding it very handy for quick
          prototyping.
        </p>
        <h3>
          You have lots of banks on your resume, how did you start working in
          banking and financial projects, do you just like money?
        </h3>
        <p>
          My first project was an application for an insurance company, not many
          people wanted to work with that client because it was “boring” work in
          “boring” offices and projects were too big and took too long to
          complete compared to other more fun clients that we had. I worked on
          that project and that project took me to another project with a large
          bank. After some time I started enjoying the complexity and
          difficulties of developing financial applications. You need to deal
          with compliance, technical complexity, scalability issues,
          reliability, security, having a team with strong ethics and being
          smart about managing risk. That complexity keeps me engaged with those
          types of projects, I’m interested not because it’s easy, I’m
          interested because it's hard and not many people want to work on them.
        </p>
        <h3>
          Has the working from home experience impacted your work? How do you
          feel about it?
        </h3>
        <p>
          My work hasn’t been impacted since I can just ship my code from my
          computer from anywhere. The part that I miss is the face to face
          interaction with colleagues but overall I think there are great
          benefits of working from home like being closer to family or saving
          commute time for working out and cooking.
        </p>
        <p>A quick look into my working from home setup.</p>
        <img src="../assets/wfh.jpg" />
        <!-- <Button>
          <template v-slot:label>
              <span>Send E-mail</span>
          </template>          
        </Button>-->
        <h2>Other FAQs</h2>
        <h3>What do you do in your free time?</h3>
        <p>
          I have a wide variety of hobbies/activities, I used to do a lot of
          scuba diving when I was living in Mexico and Florida, when I moved to
          NYC I started doing winter sports, I’m very new to ski and I love it
          although I’m not very good with the cold weather since I can get sick
          easily. I've been doing meditation, Vinyasa yoga and Hot yoga, I feel
          energized and with an enhanced focus after practicing it. That was
          before covid, I did lots of bike rides and cooking during these days
          of lockdown.
        </p>
        <h3>How would you describe yourself?</h3>
        <p>
          I consider myself to be ambitious, open minded, patient, flexible,
          thoughtful, friendly, eager in the right way, kind and I don’t take
          things personally, I have a thick skin, that’s what I usually hear
          from close people.
        </p>
      </section>
      <section class="profile-data">
        <!-- <h3>💼Worked at</h3>
        <ul>
          <li>BNY Mellon - NYC</li>
          <li>Boston Consulting Group - NYC</li>          
          <li>Accenture - NYC & FL</li>
          <li>IBMiX - Mexico City</li>
          <li>KTC Agency - Mexico city</li>
        </ul>-->
        <!-- <div
          alt="B letter with chart with upwards trend"
          class="ber-logo"
        ></div>-->
        <h2>About me</h2>
        <ul>
          <li class="title">
            <span>
              <strong>📍Location</strong>
            </span>
          </li>
          <li>
            <span>🇺🇸 NYC</span>
          </li>
          <li>
            <span>🇲🇽 Mexico city</span>
          </li>
          <li>
            <span>🌎 Remote</span>
          </li>
        </ul>

        <ul>
          <li class="title">
            <span>
              <strong>🏄🏻‍♂️ Sports</strong>
            </span>
          </li>
          <li>
            <span>🤿 Scuba Diving</span>
          </li>
          <li>
            <span>⛷ Skiing</span>
          </li>
          <li>
            <span>🚵🏻‍♂️ Mountain Biking</span>
          </li>
        </ul>

        <ul>
          <li class="title">
            <span>
              <strong>🕹 Hobbies</strong>
            </span>
          </li>
          <li>
            <span>🛸 Strategy games (Starcraft)</span>
          </li>
          <li>
            <span>👨🏻‍🍳 Cooking</span>
          </li>
          <li>
            <span>🎸 Guitars & Music</span>
          </li>
          <li>
            <span>📸 Photography</span>
          </li>
          <li>
            <span>📈 Economics</span>
          </li>
          <li>
            <span>🧱 ⚙️ Designing and building </span>
          </li>
        </ul>

        <ul>
          <li class="title">
            <span>
              <strong>📞 Schedule a call</strong>
            </span>
          </li>
          <li>
            <span>
              <a target="_blank" href="https://calendly.com/bersoriano/15min"
                >Schedule a 15min call</a
              ></span
            >
          </li>
        </ul>

        <ul>
          <li class="title">
            <span>
              <strong>✅ Feedback</strong>
            </span>
          </li>
          <li>
            <span
              >📝
              <a target="_blank" href="https://forms.gle/2vYofAh7CSUNTvXV8"
                >Send feedback here</a
              ></span
            >
          </li>
        </ul>

        <ul>
          <li class="title">
            <span>
              <strong>🐦 Social media</strong>
            </span>
          </li>
          <li>
            <a
              target="_blank"
              href="https://stackoverflow.com/users/story/7806173/"
              >Stackoverflow</a
            >
          </li>
          <li>
            <a target="_blank" href="http://linkedin.com/in/bernardosoriano/"
              >LinkedIn</a
            >
          </li>
          <li>
            <a target="_blank" href="https://twitter.com/bersoriano">Twitter</a>
          </li>
          <li>
            <a target="_blank" href="https://codepen.io/bersoriano">CodePen</a>
          </li>
        </ul>
      </section>
    </main>
    <!-- <div class="links">
      <footer>Designed and coded by @bersoriano</footer>
    </div>-->
  </div>
</template>

<script>
// @ is an alias to /src
// import Button from "../components/Button";
export default {
  name: 'Home',
  components: {
    // Button
  }
}
</script>
<style scoped>
img {
  border-radius: 0.5em;
}
.home {
  display: flex;
  flex-direction: column;
  height: 90vh;
  box-sizing: border-box;
  background-size: 160vh;
}
.ber-meet {
  background-color: silver;
  width: 100%;
  margin: auto;
  height: 340px;
  background-size: 185%;
  background-position: 47% 12%;
  background-repeat: no-repeat;
  background-image: url('../assets/bermeet.jpg');
  border-radius: 0.5em;
}
.links {
  min-height: 10vh;
  min-width: 70vw;
  margin: auto;
  border-radius: 1em;
  color: white;
}
.ber-logo {
  width: 3em;
  margin: 1em;
  height: 3em;
  background-size: 1.4em;
  background-repeat: no-repeat;
  background-image: url(/img/berlogo.05c95f83.png);
  position: absolute;
  background-position: 12px 7px;
  right: 1em;
  top: 1em;
  border: 2px solid silver;
  border-radius: 5em;
  background-image: url('../assets/berlogo.png');
}
main {
  height: 80vh;
  color: black;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
}
section {
  padding: 3em;
  max-width: 375px;
  flex: 1;
  height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
  box-sizing: border-box;
  background-color: #002c5d8c;
  background-color: #011b38cf;
  background-color: #1c5ea7c7;
}
.description {
  width: 60vw;
  max-width: 60vw;
  border-radius: 1em 0 0 1em;
  color: white;
  text-align: justify;
}
.description img {
  width: 100%;
}
.profile-data {
  min-width: 360px;
  width: 25vw;
  max-width: 25vw;
  border-radius: 0 1em 1em 0;
  background-color: #ffffffe8;
  position: relative;
}
.profile-data ul {
  background-color: #f8f8f8;
  border-radius: 1em;
  padding: 1em;
  margin: 2em 0;
  box-shadow: 6px 6px 16px 0px rgba(217 210 200 / 0.51),
    -6px -6px 26px 0 rgba(255 255 255 / 0.83);
  border: 1px solid rgb(243 243 243 / 77%);
}
.profile-data li {
  margin: 0.5em;
  height: auto;
  line-height: 1em;
}
.profile-data .title {
  margin: 0 0 1em 0;
  display: flex;
  border-bottom: 1px solid #eaeaea;
  padding: 0 0 1em 0;
}
.profile-data h2 {
  margin: 0;
  line-height: 2em;
}
h1 {
  margin: 0;
  font-size: 1.5em;
  color: #7aff62d1;
}
h1 {
  font-size: 2em;
}
h3 {
  font-size: 1em;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 0;
}
a {
}
@media only screen and (max-width: 900px) {
  main {
    flex-direction: column;
    padding: 0;
    height: 90vh;
  }
  .description {
    width: 100%;
    min-width: 90vw;
    margin: 0 0 5vh 0;
    min-height: 40vh;
    border-radius: 1em;
  }
  .profile-data {
    width: 100%;
    max-width: 100%;
    margin: 0 0 5vh 0;
    min-height: 40vh;
    border-radius: 1em;
  }
}
</style>
