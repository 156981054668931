<template>
  <div>
    <nav>
      <router-link to="/">Home</router-link>
      <a href="mailto:bsorianodev@gmail.com">Contact</a>
    </nav>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Navigation'
}
</script>

<style scoped>
nav {
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  height: 10vh;
  max-height: 10vh;
  display: flex;
  align-items: center;
  padding: 0 35vw;
  color: white;
}
nav a {
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  margin: auto;
  font-size: 1.2em;
  letter-spacing: 0.05em;
}
nav a.router-link-exact-active {
}
</style>
