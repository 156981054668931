<template>
  <Navigation />
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation'

export default {
  name: 'App',
  metaInfo: {
    title: 'Bernardo Soriano Software engineer'
  },
  components: {
    Navigation
  }
}
</script>

<style>
body {
  margin: 0;
  background: #1e3c72; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #102448,
    #163c82
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #102448,
    #163c82
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-image: url('./assets/bkg.jpg');
  background-position: center;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  letter-spacing: 0.01em;
  user-select: none;
}
.uppercase {
  text-transform: uppercase;
}
</style>
